<script setup>
import { computed } from 'vue'
import moment from 'moment'
import { Moon } from '@element-plus/icons-vue'
import { isArray } from 'lodash'
import { useRouteStore } from '~/stores/route.js'
import { ElMessage, ElNotification } from 'element-plus'

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
})

const isLoading = ref(false)

const value = computed({
  get: () => props.context._value,
  set: (val) => {
    if (props.context.node && typeof props.context.node.input === 'function') {
      props.context.node.input(val)
    } else {
      ElMessage.error('We apologize for the inconvenience. An error occurred, please try again or refresh the page.')
    }
  },
})

const store = useConveyancingCaseStore()
const employeeStore = useEmployeeCaseStore()
const quoteStore = useQuoteCaseStore()
const formStore = useFormStore()

function handleSignature() {
  if (useRouteStore().isViewOnly) {
    ElMessage.error('The form is for viewing only!')
    return
  }

  if (hasFormValidationErrors.value) {
    ElMessage.error('Please fill in all required fields!')
    return
  }

  isLoading.value = true

  if (!props.context || !props.context.node) {
    ElMessage.error('We apologize for the inconvenience. An error occurred, please try again or refresh the page.')
    isLoading.value = false
    return
  }
  value.value = 'true'
  setTimeout(() => {
    handleAutosave()
  }, 300)

  setTimeout(() => {
    isLoading.value = false
  }, 3000)
}
// This is to save signature quicker than the autosave
async function handleAutosave() {
  await formStore
    .saveForm({ status: 'autosaved' })
    .catch((e) => ElNotification({ title: e.response?.data?.message || 'There was an error saving the form', type: 'error' }))
}

const conveyancingCase = computed(() => store.conveyancingCase)
const employeeCase = computed(() => employeeStore.employeeCase)
const errors = computed(() => formStore.errors)

const filteredErrors = computed(() => {
  if (!isArray(errors.value)) return []
  return errors.value.map((error) => error.meta?.i18nArgs?.[0]?.node.name).filter((name) => !name.includes('signature'))
})

const hasFormValidationErrors = computed(() => filteredErrors.value?.length > 0)

const form = computed(() => formStore.form)

onMounted(() => {
  if (!quoteStore.quoteCase?.qc_case_ref && store.conveyancingCase?.length === 0) {
    store.fetchConveyancingCase()
  }

  if (formStore.form.length === 0) {
    formStore.fetchForm()
  }
})

function getFullName(clientId) {
  return [`title`, `forename`, `middle_name`, `surname`]
    .map((key) => formStore.payload[`clients.${clientId}.${key}`])
    .filter((value) => value)
    .join(' ')
}

const clientDetails = computed(() => {
  const clientMapping = {
    signature_client: conveyancingCase.value?.client?.name,
    signature_client2: conveyancingCase.value?.client2?.name,
    signature_client3: conveyancingCase.value?.client3?.name,
    signature_client4: conveyancingCase.value?.client4?.name,
    'clients.client1.signature': getFullName('client1'),
    'clients.client2.signature': getFullName('client2'),
    'clients.client3.signature': getFullName('client3'),
    'clients.client4.signature': getFullName('client4'),
    signature_of_employee: employeeCase?.value?.name,
  }

  const donorTypes = [
    'signature_declaration_donor_1a',
    'signature_declaration_donor_1b',
    'signature_declaration_donor_2a',
    'signature_declaration_donor_2b',
    'signature_declaration_donor_3a',
    'signature_declaration_donor_3b',
    'signature_declaration_donor_4a',
    'signature_declaration_donor_4b',
    'signature_letter_lender_donor_1a',
    'signature_letter_lender_donor_1b',
    'signature_letter_lender_donor_2a',
    'signature_letter_lender_donor_2b',
    'signature_letter_lender_donor_3a',
    'signature_letter_lender_donor_3b',
    'signature_letter_lender_donor_4a',
    'signature_letter_lender_donor_4b',
  ]

  const poaClients = {
    signature_declaration_poa_1: conveyancingCase.value?.client?.name,
    signature_declaration_poa_2: conveyancingCase.value?.client2?.name,
    signature_declaration_poa_3: conveyancingCase.value?.client3?.name,
    signature_declaration_poa_4: conveyancingCase.value?.client4?.name,
  }

  if (Object.prototype.hasOwnProperty.call(clientMapping, props.context.node.name)) {
    return clientMapping[props.context.node.name]
  } else if (donorTypes.includes(props.context.node.name)) {
    return conveyancingCase.value.donor_main?.full_name_company_name
  } else if (Object.prototype.hasOwnProperty.call(poaClients, props.context.node.name)) {
    return poaClients[props.context.node.name]
  }
  return ''
})

const clientFont = computed(() => {
  const donorTypes = [
    'signature_declaration_donor_1a',
    'signature_declaration_donor_1b',
    'signature_declaration_donor_2a',
    'signature_declaration_donor_2b',
    'signature_declaration_donor_3a',
    'signature_declaration_donor_3b',
    'signature_declaration_donor_4a',
    'signature_declaration_donor_4b',
    'signature_letter_lender_donor_1a',
    'signature_letter_lender_donor_1b',
    'signature_letter_lender_donor_2a',
    'signature_letter_lender_donor_2b',
    'signature_letter_lender_donor_3a',
    'signature_letter_lender_donor_3b',
    'signature_letter_lender_donor_4a',
    'signature_letter_lender_donor_4b',
  ]

  if (donorTypes.includes(props.context.node.name)) {
    return 'font-signature-1'
  } else {
    const clientFontMapping = {
      signature_client: 'font-signature-1',
      signature_client2: 'font-signature-2',
      signature_client3: 'font-signature-3',
      signature_client4: 'font-signature-4',
      'clients.client1.signature': 'font-signature-1',
      'clients.client2.signature': 'font-signature-2',
      'clients.client3.signature': 'font-signature-3',
      'clients.client4.signature': 'font-signature-4',
      signature_of_employee: 'font-signature-1',
    }
    return clientFontMapping[props.context.node.name] || 'default-font'
  }
})
</script>

<template>
  <el-card v-if="clientDetails" class="box-card" body-class="flex h-full flex-col gap-3">
    <p class="text-sm font-semibold">{{ clientDetails }}</p>
    <div class="relative flex flex-1 flex-col items-center justify-center gap-1 rounded border border-dashed p-3 py-12 tracking-wide">
      <p :class="clientFont" class="ftext-center text-xl">{{ clientDetails }}</p>
      <p class="text-xs font-medium text-gray-400">{{ moment(form?.updated_at ?? Date.now()).format('DD-MM-YYYY HH:mm:ss') }}</p>

      <template v-if="!value || (value && isLoading)">
        <div class="absolute inset-0 flex items-center justify-center bg-white/20 backdrop-blur-sm">
          <el-button :loading="isLoading" :disabled="isLoading" :icon="Moon" class="hover:px-6" type="primary" round @click="handleSignature">
            Check to Sign
          </el-button>
        </div>

        <el-button type="danger" size="small" class="absolute top-full -mt-3 !text-xs" plain round>Signature is required</el-button>
      </template>
    </div>
  </el-card>
</template>

<style scoped></style>
